<template>
  <div>
    <!-- PANTALLA DE OPCIONES DE PAGO -->
    <!-- <div v-if="showUnverified">
      <vx-card
        class="mt-6"
        card-background="dark"
        title="¡No olvides verificar tu identidad!"
        title-color="white"
      >
        <p style="color: white">
          En la Red, es importante verificar que tus datos concuerden con tu
          identidad. Verifícala lo más pronto posible, ya que de lo contrario<br />
          tu proyecto no podrá publicarse a fondeo y no podremos recabar los
          fondos para comenzar con la instalación de tu sistema,<br />
          aún y cuando ya hayas realizado tu pago inicial.
        </p>
        <vs-button
          color="primary"
          type="border"
          class="mt-3"
          @click="$router.push({ name: 'perfilSolicitanteAbout' })"
          >Verificar mi identidad</vs-button
        >
      </vx-card>
    </div> -->

    <!-- CARD DE DOMICILIACION -->
    <!-- <vx-card
      v-if="showMainOptions"
      class="mt-6"
      title="A. Quiero que se cobre automáticamente a mi cuenta de banco."
    >
      <p class="text-md">
        Al elegir el cobro automático a una cuenta de tu preferencia podrás
        elegir el día en que se realice después<br />de indicar una cuenta
        CLABE. De ser necesario podrás cancelar el cobro 2 días antes del primer
        intento.
      </p>

      <div v-if="accounts.length > 0">
        <vs-table :data="accounts">
          <template slot="thead">
            <vs-th>Banco</vs-th>
            <vs-th>CLABE</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].bank">
                {{ data[indextr].bank }}
              </vs-td>
              <vs-td :data="data[indextr].clabe">
                {{ data[indextr].clabe }}
              </vs-td>
              <vs-td :data="data[indextr].clabe">
                <div class="flex items-center flex-grow justify-left mt-5">
                  <feather-icon icon="TrashIcon"></feather-icon>
                  <feather-icon
                    class="ml-3"
                    v-if="data[indextr].is_default"
                    icon="StarIcon"
                  ></feather-icon>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>

      <div class="flex items-center flex-grow justify-left">
        <vs-button
          v-if="!showAccountForm"
          color="primary"
          class="mt-5"
          @click="showAddAccount()"
          >Añadir cuenta</vs-button
        >
      </div>

      <div
        v-if="showAccountForm"
        class="flex items-center flex-grow justify-left mt-5"
      >
        <div class="centerx default-input">
          <vs-input
            label="CLABE"
            placeholder="Ingresa los 18 dígitos"
            v-model="clabe"
          />
        </div>
        <vs-select
          class="ml-3 selectExample"
          width="300px"
          label="Carátula de cuenta"
          v-model="select1"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in options1"
          />
        </vs-select>
        <div class="flex items-center flex-grow justify-left">
          <vs-button
            color="primary"
            class="mt-5 ml-3"
            type="border"
            @click="addAccount()"
            >Añadir cuenta</vs-button
          >
        </div>
      </div>

      <div v-if="accounts.length > 0">
        <br />
        <label for="" class="vs-input--label"
          >¿En qué día deseas que se haga el cargo?</label
        >
        <datepicker
          class="mb-4"
          label="¿En qué día deseas que se haga el cargo?"
          :language="es"
          v-validate="'required'"
          data-vv-name="domiciliation_date"
          format="dd/MM/yyyy"
          v-model.lazy="domiciliation_date"
        />

        Al hacer clic en “Aceptar” firmas electrónicamente nuestro
        <strong>contrato de adhesión</strong> para el cobro domiciliado de tu
        pago inicial
        <div class="flex items-center flex-grow justify-left">
          <vs-button
            color="primary"
            class="mt-3"
            @click="showSuccessDomiciliation"
            >Aceptar</vs-button
          >
          <vs-button class="mt-3 ml-5">Cancelar</vs-button>
        </div>
      </div>
    </vx-card> -->
    <!-- CARD DE DOMICILIACION -->

    <!-- CARD DE TANSFERENCIA ELECTRONICA-->
    <vx-card class="mt-base" v-if="clabeStp">
      <div class="vx-col w-full">
        <div class="flex items-center justify-left mb-3">
          <img :src="transferIcon" style="width:30px" alt="" class="mr-3">
          <h3>Quiero realizar una transferencia electrónica.</h3>
        </div>
      </div>
      <div class="vx-row mt-5">
        <div class="vx-col lg:w-2/3 md:w-1/2 w-full mb-5">
          <p>
            Podrás realizar tu pago enviando un SPEI o transferencia electrónica desde 
            tu banca por internet seleccionando el listado de bancos <strong>STP (Sistema de Transferencias y Pagos)</strong> 
            a la cuenta CLABE que aparece a continuación. <strong>Esta CLABE es única y fue generada especialmente para ti</strong>.<br><br>
            Considera que <strong>deberás ser titular de las cuentas</strong> desde donde envíes tu pago, ya que de lo contrario, tendremos que <strong>realizar un reembolso y tu pago no será exitoso</strong>. 
          </p>
        </div>
        <div class="vx-col lg:w-1/3 md:w-1/2 w-full mb-5">
          <div class="vx-row">
            <div class="vx-col w-full">
              <p class="bold">Tu CLABE</p>
              <p class="info-border-inline mb-6">{{clabeStp}}</p>
              <vs-button color="primary" @click="copyToClipboard(clabeStp)">Copiar CLABE</vs-button>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row mt-base">
        <vs-divider color="success" class="bold"></vs-divider>
          <div class="vx-col w-full mt-base">
            <h3>¿No sabes cuáles son los pasos a seguir? ¡Te los explicamos!</h3>
          </div>
          <div class="vx-col lg:w-2/3 md:w-1/2 w-full mt-5 mb-5">
            
            <template v-for="(item, index) in transferSteps">
              <div class="flex items-center justify-left mb-6">
                <p><span class="list-number mr-3">{{ item.step }}</span></p>
                <p v-if="item.step == 3">
                  Ingresa la cuenta CLABE personalizada que te generamos: <b>{{clabeStp}}</b>.
                </p>
                <div v-else v-html="item.text"></div>
              </div>
            </template>
            <p class="mt-5 ml-base">
              <i>Ya que hayas realizado el pago, recibirás un correo de confirmación en un plazo de 2 horas. Si tienes dudas, estamos para ayudarte a través del chat de la plataforma.</i>
            </p>
          </div>
          <div class="vx-col lg:w-1/3 md:w-1/2 w-full mb-5">
            <youtube-video-widget :media="videoStp" cWidth="100%" cHeight="18rem" title="¿Cómo llenar la solicitud?"/>
          </div>
      </div>
      <!-- <div class="vx-row mt-3">
        <div class="vx-col lg:w-1/2 w-full">
          <youtube-video-widget :media="videoStp" title="¿Cómo llenar la solicitud?"/>
        </div>
        <div class="vx-col w-full mt-5">
          <p class="mb-3">
            <span class="list-number">1</span> Una vez que entres al portal de
            tu banca en línea o tu app de banca móvil, selecciona la opción
            enviar un <strong>SPEI</strong>.
          </p>
          <p class="mb-3">
            <span class="list-number">2</span> En el listado de bancos destino,
            selecciona <strong>STP (Sistema de Transferencias y Pagos).</strong>
          </p>
          <p class="mb-3">
            <span class="list-number">3</span> Ingresa la cuenta CLABE
            personalizada que te generamos: <strong>{{clabeStp}}</strong>.
          </p>
          <p class="mb-3">
            <span class="list-number">4</span> En el campo “referencia” o
            “concepto” podrás escribir lo que tú consideres.
          </p>
          <p class="mb-3">
            <span class="list-number">5</span> En el campo “beneficiario”,
            escribe tu nombre completo.
          </p>
        </div>
      </div>
      <p>
        Ya que hayas realizado el proceso, un correo de confirmación en un
        plazo<br />
        máximo de 2 horas. Si tienes dudas, estamos para ayudarte a través<br />
        del chat de la plataforma.
      </p> -->
    </vx-card>
    <vx-card class="mt-base" v-else title="Quiero realizar una transferencia electrónica.">
      <p>
        Para realizar la transferencia desde tu banca en línea, deberás generar una cuenta CLABE personalizada. Esta CLABE es única y fue generada especialmente para ti. 
        Para generarla contáctanos a través del chat de la plataforma o en el whatsapp <strong>81–2861-9415</strong>.
      </p>
    </vx-card>
    <!-- CARD DE TANSFERENCIA ELECTRONICA-->
    
    <!-- CARD DE OXXO-->
    <!-- <vx-card class="mt-base" v-if="clabeOxxo" title="Quiero realizar un depósito en efectivo en una sucursal OXXO."
    >
      <img
        alt=""
        width="250"
        src="https://blog.boletopolis.com/wp-content/uploads/2017/03/oxxo_pay_Grande.png"
      />
      <p class="mt-5">
        Para realizar el depósito deberás indicar el número que se presenta a
        continuación en la sucursal OXXO.<br />
        Al elegir esta forma de pago considera que existen comisiones por parte
        de OXXO para realizarlo.
      </p>
      <div class="vx-row mt-5">
        <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
          <p class="info-border-inline mb-6">{{clabeOxxo}}</p>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col lg:w-1/2 md:w-10/12 w-full">
          <h5>¿No sabes cuáles son los pasos a seguir? ¡Te los explicamos!</h5>
        </div>
      </div>
      <div class="vx-row mt-3">
        <div class="vx-col w-full">
          <p class="mb-3">
            <span class="list-number">1</span> Acude a la tienda OXXO más
            cercana.
          </p>
          <p class="mb-3">
            <span class="list-number">2</span> Indica en caja que quieres
            realizar un pago de OXXOPay.
          </p>
          <p class="mb-3">
            <span class="list-number">3</span> Dicta al cajero el número de
            referencia en esta ficha para que la tecleé directamente an la
            pantalla de venta.
          </p>
          <p class="mb-3">
            <span class="list-number">4</span> Realiza el pago correspondiente
            con dinero en efectivo.
          </p>
          <p class="mb-3">
            <span class="list-number">5</span> Al confirmar tu pago, el cajero
            te entregará un comprobante impreso. En él podrás verificar que se
            haya realizado correctamente.<br />Conserva este comprobante de
            pago.
          </p>
        </div>
      </div>
      <p>
        Ya que hayas realizado el proceso, un correo de confirmación en un
        plazo<br />
        máximo de 2 horas. Si tienes dudas, estamos para ayudarte a través<br />
        del chat de la plataforma.
      </p>
    </vx-card> -->
    <!-- CARD DE OXXO-->

    <!-- CARD DE FICHA-->
    <!-- <vx-card
      v-if="uniqueSlip"
      class="mt-base"
      card-background="primary"
      title="¡Descarga tu ficha única!"
      title-color="white"
    >
      <p style="color: white">
        Recuerda que siempre podrás descargar tu ficha única para
        realizar el pago de las opciones de <strong>transferencia electrónica</strong> o el
        <strong>depósito de efectivo</strong> en OXXO.
      </p>
      <vs-button color="" type="border" class="mt-3" @click="downloadUniqueSlip">Descargar ficha</vs-button>
    </vx-card> -->
    <!-- CARD DE FICHA-->
  </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper";
import dateHelper from "@mixins/dateHelper";
import Datepicker from "vuejs-datepicker";
import YoutubeVideoWidget from '@components/supplier/widgets/YoutubeVideoWidget.vue';
// import { es } from "vuejs-datepicker/dist/locale";

// const columnDefinitions = [
//   { title: "FECHA", key: "date", sortable: true },
//   { title: "CONCEPTO", key: "concept", sortable: true },
//   //{ title: "REFERENCIA", key: "reference", sortable: true },
//   { title: "INGRESO", key: "income", sortable: true },
//   { title: "EGRESO", key: "outcome", sortable: true },
//   { title: "SALDO", key: "balance", sortable: true },
// ];

export default {
  name: "PaymentOptions",
  mixins: [formatHelper, dateHelper],
  components: {
    Datepicker,
    YoutubeVideoWidget
  },
  data(){
    return {
      transferIcon: require('@assets/images/figs/transfer-icon.svg'),
      clabeOxxo: null,
      clabeStp: null,
      uniqueSlip:null,
      isMounted: false,
      failed: false,
      videoStp: { sources: [{ type: 'video/youtube', src: 'https://www.youtube.com/watch?v=7D7k51i-tZk' }], poster: 'https://cdn.redgirasol.com/green/COVERS_VIDEOS/transferencia_electronica.png' },
      transferSteps: [
        {
          step: "1",
          text: "Una vez que entres al portal de tu banca en línea o tu app de banca móvil, selecciona la opción enviar un <strong>SPEI</strong>."
        },
        {
          step: "2",
          text: "En el listado de bancos destino, selecciona <strong>STP (Sistema de Transferencias y Pagos).</strong>"
        },
        {
          step: "3",
          text: ""
        },
        {
          step: "4",
          text: "En el campo “referencia” o “concepto” podrás escribir lo que tú consideres."
        },
        {
          step: "5",
          text: "En el campo “beneficiario”, escribe tu nombre completo."
        }
      ]
    }
  },
  async mounted() {
    this.showLoading(true)
    this.isMounted = false
    await this.getApplicantPaymentInfo();
    this.isMounted = true
    this.showLoading(false)
  },
    filters: {
        human_date(date) {
            return moment(date).lang("es").format("D MMM YYYY");
        }
    },
  methods: {
    async getApplicantPaymentInfo(){
      try{
        const response = await axios.get(`api/v1/applicant/${this.ApplicantId}/paymentData`);
        if (response.data.clabe_oxxo) {
          this.clabeOxxo = response.data.clabe_oxxo.match(/.{1,3}/g).join(" ");
        }
        this.clabeStp = response.data.clabe_stp;
        this.user.clabeStp = this.clabeStp;
        this.uniqueSlip = response.data.unique_slip;
      }
      catch (e) {
        this.failed = true;
      }
    },
    downloadUniqueSlip() {
        // se genera log de accion
        this.saveApplicantLogAction("LC19-35");
        window.open(`${this.uniqueSlip.public_url}`, "_blank");
    },
    copyToClipboard(value){
      const el = document.createElement('textarea');
      el.value = value;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.successNotif("CLABE copiada al portapapeles");
    },
  }
};
</script>

<style>
.list-number {
  font-size: 16px;
  background-color: #CBFF00;
  color: black;
  padding: 2px 11px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: inline-block;
  font-weight: bold;
  margin-right: 5px;
}

.mbot {
  margin-bottom: 15px;
}
</style>