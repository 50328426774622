<template>
  <div>
    <big-title-color variant-class="dark">
      <template v-slot:text>Realizar<span> pagos</span>.</template>.
    </big-title-color>
    <!-- CARD PARA CANCELAR O MODIFICAR LOS PAGOS AUTOMATICOS -->
    <!-- <vx-card
        class="mt-base"
        title="¡Tu pago automático ha sido activado!"
        title-color="#0F7B0B"
        card-background="#E9F8E8"
        v-if="user.payment_method == 3">
        <p class="text-md">
          Recuerda que el pago se realizará el día que te corresponda según tu plan de pagos, pero en caso de no haber fondos suficientes serás notificado y te corresponderá hacer el pago de manera manual ese mes.
          <br><br>
          Si deseas modificar el pago automático para que sea realizado a otra cuenta de banco, da clic en Desactivar pago automático y vuelve activarlo seleccionando la cuenta de banco de tu preferencia. Si la cuenta de banco no aparece, en ese mismo paso te aparecerán las instrucciones para que puedas agregar una cuenta de banco nueva.
        </p>
        <vs-button color="#20B61A" type="border" class="sm:px-base mt-5 px-4" @click="showPopUpCancelDirectBilling">Desactivar pago automático</vs-button>
    </vx-card> -->
    <!-- CARD PARA CANCELAR O MODIFICAR LOS PAGOS AUTOMATICOS -->
    <!-- CARD PARA ACTIVAR LOS PAGOS AUTOMATICOS -->
    <!-- <vx-card
        class="mt-base"
        title="¡Olvida las preocupaciones y activa el pago automático!"
        title-color="#0F7B0B"
        card-background="#E9F8E8"
        v-else>
        <p class="text-md">
          Mantén un historial de pagos puntual, fortalece tu perfil crediticio y puertas a mejores oportunidades financieras en el futuro. 
          No dejes que los olvidos o el tiempo consumido por los pagos manuales te detengan. 
          ¡Da el paso hacia un camino financiero más simple y exitoso activando el pago automático ahora mismo, es muy sencillo!
        </p>
        <vs-button color="#20B61A" class="sm:px-base mt-5 px-4" @click="goToAutomaticPayments">Activar pago automático</vs-button>
    </vx-card> -->
    <!-- CARD PARA ACTIVAR LOS PAGOS AUTOMATICOS -->

    <payment-options></payment-options>
    <vx-card class="mt-base" title="Consulta tu plan de pagos.">
        <p class="text-md"> 
          Visualiza tu plan de pagos fácilmente. ¡Conoce cuotas, fechas y más con un solo clic!
        </p>
        <vs-button color="primary" class="sm:px-base mt-5 px-4" @click="showPopUpPayments">Ver plan de pagos</vs-button>
    </vx-card>
    <!-- Popup para tabla de amortización consolidada -->
    <vs-popup :active.sync="popUpPaymentsActive" title="Plan de pagos" class="max-width-popup header-primary">
      <applicant-payments-plan project-id="-1" :master-global="true"/>
    </vs-popup>

    <!-- Popup para cancelacion de domiciliacion -->
    <vs-popup :active.sync="popUpDirectBillingCancelContract" title="Desactivar pago automático" class="extended-popup header-primary">
      <latest-direct-billing-cancel-contract />
      <div class="vx-row mt-base mb-base">
        <div class="vx-col w-full">
          <vs-button color="#20B61A" class="sm:px-base ml-5 mt-5" @click="cancelDirectBilling">Desactivar pago automático</vs-button>
        </div>
      </div>
    </vs-popup>

    <!-- Componente para obtener la geolocalización -->
    <rg-geolocator ref="rgGeolocator" />
  </div>
</template>
<script>
import PaymentOptions from "@components/applicant/PaymentOptions.vue";
import ApplicantPaymentsPlan from "@components/applicant/projects/detail/ApplicantPaymentsPlan";
import LatestDirectBillingCancelContract from "@views/share/contracts/DirectBillingContract/LatestDirectBillingCancelContract.vue";

export default {
  components: {
    PaymentOptions,
    ApplicantPaymentsPlan,
    LatestDirectBillingCancelContract
  },
  data() {
    return {
      contractCancelDirectBillingType: 20,
      popUpPaymentsActive: false,
      popUpDirectBillingCancelContract: false
    }
  },
  methods: {
   showPopUpPayments() {
        // se genera log de accion
        this.saveApplicantLogAction("LC19-36");
        this.popUpPaymentsActive = true;
   },
   showPopUpCancelDirectBilling() {
      // se pide la geolocalizacion para la operacion
      this.$refs.rgGeolocator.askForGeolocation();
      this.popUpDirectBillingCancelContract = true;
   },
   async goToAutomaticPayments() {
     await this.$router.push({ name: 'pagoAutomaticoSolicitante' });
   },
   async cancelDirectBilling() {
      this.showLoading(true, "Guardando información...");
      try {
        let payload = {
              geolocation: this.CurrentGeolocationData,
              contractType: this.contractCancelDirectBillingType
        };
         await axios.post("/api/v1/applicant/" + this.ApplicantId + "/cancelDirectBilling", payload);
         await this.getUserInfo(this.UserRole);
         this.showLoading(false);
         this.popUpDirectBillingCancelContract = false;
         await this.$router.go();
      } catch (error) {
         this.showLoading(false);
         if (typeof error.response.data.message !== 'undefined') {
            this.errorNotifDialog('Operación fallida', error.response.data.message);
         } else {
            this.failedOperationNotif();
         }
      }
   }
  }
}
</script>

<style>
   .con-vs-dialog{
      z-index: 100000 !important;
   }
</style>