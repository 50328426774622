<template>
    <section>
        <div v-if="isMounted" class="container-fluid">
            <div class="col-md-12">
                <div class="alt-2"></div>
                <div class="vx-row">
                    <div class="vx-col w-full">

                        <h1 style="padding-top: 4pt;text-align: center;">FORMATO PARA
                            CANCELAR LA DOMICILIACIÓN</h1>
                        <br />

                        <h2 style="text-align: right;">Monterrey,
                            Nuevo León, a {{ midDateFormat(todayDate) }}</h2>

                        <p style="text-indent: 0pt;text-align: left;"><b><u>Banco Regional de Monterrey, S.A., Institución
                                    de
                                    Banca Múltiple, Banregio Grupo Financiero</u></b></p>

                        <p style="text-indent: 0pt;text-align: left;">Solicito a ese banco que cancele la domiciliación del
                            pago siguiente:</p>
                        <ol id="l1">
                            <li data-list-text="1.">
                                <p style="padding-left: 19pt;text-indent: -14pt;text-align: left;">Nombre del proveedor del
                                    bien, servicio o crédito: <b>RED GIRASOL S.A.P.I. de C.V., INSTITUCIÓN DE FINANCIAMIENTO
                                        COLECTIVO</b>.</p>
                                <br />

                            </li>
                            <li data-list-text="2.">
                                <p style="padding-left: 19pt;text-indent: -14pt;text-align: left;">Bien, servicio o crédito
                                    correspondiente a la domiciliación que se solicita cancelar: _<b>N/A</b><span
                                        class="s2"> </span>.</p>

                                <p style="padding-left: 19pt;text-indent: 0pt;text-align: left;">En su caso, el número de
                                    identificación generado por el Proveedor (dato no obligatorio): <span
                                        class="s2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>.</p>
                                <br />
                            </li>
                            <li data-list-text="3.">
                                <p style="padding-left: 19pt;text-indent: -14pt;text-align: left;">Cualquiera de los Datos
                                    de identificación de la cuenta donde se efectúa el cargo, siguientes:</p>
                                <p class="s2" style="padding-left: 19pt;text-indent: 0pt;text-align: left;"><span
                                        class="p">Número de tarjeta de débito (16 dígitos): _</span><span
                                        class="h2">N/A;</span>
                                        <br>
                                    <span class="p">Clave Bancaria
                                        Estandarizada (“CLABE”) de la cuenta (18 dígitos): </span>&nbsp; <span
                                        class="h2">{{ accountClabe }}</span> <span class="p">, o</span></p>
                                <p class="s2" style="padding-left: 19pt;text-indent: 0pt;text-align: left;"><span
                                        class="p">Número del teléfono móvil asociado a la cuenta: </span>&nbsp; <span
                                        class="h2">N/A</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span
                                        class="p">.</span></p>
                                <br />
                            </li>
                            <li data-list-text="4.">
                                <p style="padding-left: 19pt;text-indent: -14pt;text-align: left;">La domiciliación que
                                    solicitó cancelar se hace para cubrir los pagos de algún crédito o préstamo que adeudo:
                                </p>
                            </li>
                        </ol>
                        <p class="s3" style="padding-left: 19pt;text-indent: 0pt;text-align: left;"><span class="p">sí
                            </span>_X_ <span class="p">no </span>□</p>
                        <p style="padding-top: 12pt;padding-left: 19pt;text-indent: 0pt;text-align: justify;">En caso de que
                            la domiciliación que solicitó cancelar se realice para efectuar los pagos de algún crédito que
                            adeudo, reconozco que esta cancelación ocasionaría que se dejen de hacer los pagos a ese crédito
                            con los recursos depositados en mi cuenta que indico en esta solicitud. Ante esto, a menos que
                            yo cubra de alguna otra manera los pagos que debo realizar por ese crédito, esta cancelación
                            ocasionaría el incumplimiento de las obligaciones que tengo a mi cargo por dicho crédito, lo
                            cual haría que esa situación sea reportada a una sociedad de información crediticia (conocida
                            como buró de crédito).</p>
                        <br />
                        <p style="padding-left: 19pt;text-indent: 0pt;text-align: justify;">Además de lo anterior, en caso
                            que la domiciliación que solicito cancelar haya sido realizada para cubrir los pagos de un
                            Crédito Asociado a la Nómina, según se define dicho término en las disposiciones generales
                            emitidas por el Banco de México en la Circular 3/2012, que designé para que los recursos de
                            dichos pagos sean cargados a una cuenta de depósito en que recibo mi salario y demás
                            prestaciones laborales, reconozco que: 1) la sola cancelación de dicha domiciliación hecha con
                            anterioridad al vencimiento del Crédito Asociado a la Nómina, con independencia de que haga los
                            pagos de los adeudos pendientes en tiempo y forma, será reportada por la institución de crédito
                            o SOFOM E.R. Vinculada a una sociedad de información crediticia para fines informativos y
                            además, ocasionará que ninguna institución bancaria o SOFOM E.R. Vinculada podrá otorgar un
                            nuevo Crédito Asociado a la Nómina durante los próximos nueve meses a partir de la fecha de esta
                            solicitud de cancelación, y 2) La institución bancaria o SOFOM E.R. Vinculada a que me haya
                            otorgado dicho Crédito Asociado a la Nómina podrá rescindir el contrato que hayamos celebrado
                            para tal efecto, o bien, incrementar la tasa de interés que resulte aplicable al mencionado
                            Crédito Asociado a la Nómina en los términos acordados en el contrato respectivo.</p>
                        <br />
                        <p style="padding-left: 19pt;text-indent: 0pt;text-align: justify;">Estoy enterado de que la
                            cancelación es sin costo a mi cargo y que surtirá efectos en un plazo no mayor a tres días
                            hábiles bancarios contado a partir de la fecha de presentación de esta solicitud.</p>
                        <br />
                        <h2 style="text-indent: 0pt;text-align: center;">A t e n t a m e n t e ,</h2>
                        <br /><br />
                        <p class="s1" style="text-align: center;">
                            <span class="h2">{{ user.complete_name }}</span>
                            <br>
                            <span class="s7"> </span><span class="h2">(NOMBRE O RAZÓN SOCIAL DEL TITULAR DE LA
                                CUENTA)</span>
                        </p>


                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import dateHelper from "@mixins/dateHelper";
export default {
    name: "LatestDirectBillingCancelContract",
    props: ["userRole"],
    mixins: [dateHelper],
    data() {
        return {
            todayDate: new Date(),
            isMounted: false,
            accountClabe: null,
        }
    },
    async mounted() {
        this.showLoading(true)
        this.isMounted = false
        await this.getApplicantDirectBillingAccount();
        this.isMounted = true
        this.showLoading(false)
    },
    computed: {
        loanType() {
            if (this.userRole == "investor") {
                return "Inversión RedGirasol";
            }
            else {
                return "Préstamo RedGirasol";
            }
        },
        roleId() {
            if (this.userRole == "investor") {
                return "IID: " + this.InvestorId;
            }
            else {
                return "CID: " + this.ApplicantId;
            }
        },
    },
    methods: {
        async getApplicantDirectBillingAccount(){
            try{
                const response = await axios.get(`api/v1/applicant/${this.ApplicantId}/directBillingAccount`);
                this.accountClabe = response.data.account_clabe;
            }
            catch (e) {
                this.failed = true;
            }
        },
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

h1 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

.h3 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 9pt;
}

.h2,
h2 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
}

.p,
p {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
    margin: 0pt;
}

.s2 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 10pt;
}

.s3 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 18pt;
}

.s4 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

li {
    display: block;
}

#l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
}

#l1>li>*:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal)". ";
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

#l1>li:first-child>*:first-child:before {
    counter-increment: c1 0;
}
</style>